/* Import Tailwind base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
body {
  font-family: "Inter", sans-serif; /* A modern sans-serif font */
  background-color: #f9fafb; /* Light background by default */
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth; /* Smooth scroll for anchor links */
}

/* Typography Adjustments */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
}

/* Button Styles */
button {
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}

/* Blinking cursor effect */
.blinking-cursor {
  animation: blink 1.5s step-start infinite; /* Slowed down for better UX */
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* Animations for fade-in and sliding elements */
.animate-fade-in {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Faster fade-in for smaller components */
.animate-fade-in-fast {
  animation: fadeInFast 0.5s ease-out forwards;
}

@keyframes fadeInFast {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Navbar Hover Effect */
.nav-item:hover {
  color: #3b82f6; /* Blue on hover */
  border-bottom: 2px solid #3b82f6;
}

/* Dark Mode Support for Images */
.dark img {
  filter: brightness(0.8); /* Make images slightly darker in dark mode */
}

.light img {
  filter: brightness(1);
}

/* Hover effect for icons */
.hover-icon:hover {
  transform: rotate(12deg);
  transition: transform 0.3s ease;
}

/* Project Card Hover Effect */
.project-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Slight shadow on hover */
}

/* Modal Styling */
.modal {
  backdrop-filter: blur(5px); /* Add blur effect to the background */
  transition: transform 0.3s ease;
}

.modal.show {
  transform: scale(1);
}

/* Form Styles */
input, textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.75rem;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
}

input:focus, textarea:focus {
  border-color: #3b82f6; /* Blue border on focus */
}

input::placeholder, textarea::placeholder {
  color: #9ca3af; /* Slightly darker placeholder */
}

/* Tooltip Effect */
.tooltip {
  position: absolute;
  background: black;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip.show {
  opacity: 1;
}

/* Media Queries for Responsive Design */

/* Small screens (mobile) */
@media (max-width: 640px) {
  .h1 {
    font-size: 2rem; /* Adjusted size for hero text */
  }

  .project-card {
    margin-bottom: 2rem; /* More spacing between project cards */
  }

  .grid {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 90vw;
  transition: transform 0.3s ease;
}

/* Button Styles for CTA */
.cta-button {
  padding: 0.75rem 1.5rem;
  background-color: #3b82f6;
  color: white;
  border-radius: 8px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #2563eb; /* Slightly darker blue on hover */
  transform: scale(1.05);
}

html {
  scroll-behavior: smooth;
}
